import { LETTERS } from "./lettters";
import { placeLetterHorizontal } from "./placeLetterHorizontal";
import { placeLetterVertical } from "./placeLetterVertical";

export const orderWords = (wordsArray2D) => {
  const jumbledArray = new Array(105).fill("0");
  const visited = {};
  const chance = Math.random();

  if (chance > 0.5) {
    placeLetterHorizontal(wordsArray2D, jumbledArray, visited);
  } else {
    placeLetterVertical(wordsArray2D, jumbledArray, visited);
  }

  jumbledArray.forEach((letter, idx) => {
    if (letter === "0") {
      jumbledArray[idx] = LETTERS[Math.floor(Math.random() * LETTERS.length)];
    }
  });

  return jumbledArray;
};
