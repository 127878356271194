import React, { useEffect, useRef, useState } from "react";
import randomWords from "random-words";
import { orderWords } from "../utils/orderWords";
import { fourLetterWords } from "../utils/four-letter-words";

export default function Board() {
  const [words, setWords] = useState([]);
  const [wordToLetter, setWordToLetter] = useState([]);
  const [jumbledArray, setJumbledArray] = useState([]);
  const [selectedLetters, setSelectedLetters] = useState("");
  const [correctGuesses, setCorrectGuesses] = useState([]);
  const [vertical, setVertical] = useState(true);
  const [horizontal, setHorizontal] = useState(true);
  const valueRef = useRef(null);

  useEffect(() => {
    setSelectedLetters("");
  }, []);

  useEffect(() => {
    setWords(
      randomWords({
        exactly: 5,
        formatter: (word) => {
          return word.length >= 7
            ? fourLetterWords[
                Math.floor(Math.random() * fourLetterWords.length)
              ]
            : word;
        },
      })
    );
  }, []);

  useEffect(() => {
    setWordToLetter(
      words.map((word) => {
        return word.split("");
      })
    );
  }, [words]);

  useEffect(() => {
    const newJumbledArray = orderWords(wordToLetter);
    setJumbledArray(newJumbledArray);
  }, [wordToLetter]);

  const checkVertical = (idx) => {
    const prevIdx = valueRef.current;
    if ((idx - 15 === prevIdx || idx + 15 === prevIdx) && horizontal) {
      setHorizontal(false);
      setVertical(true);

      return false;
    } else if (idx - 1 === prevIdx || idx + 1 === prevIdx) {
      return true;
    }
    return false;
  };

  const checkHorizontal = (idx) => {
    const prevIdx = valueRef.current;
    if ((idx - 1 === prevIdx || idx + 1 === prevIdx) && vertical) {
      setHorizontal(true);
      setVertical(false);
      return false;
    } else if (idx - 15 === prevIdx || idx + 15 === prevIdx) {
      return true;
    }
    return false;
  };

  const checkFoundAll = () => {
    if (correctGuesses.length === 5) {
      restartGame();
    }
    return;
  };

  const handleLetterClick = (letter, idx) => {
    if (idx === valueRef.current) return;
    isNeighbor(idx)
      ? setSelectedLetters((prev) => prev.concat(letter))
      : handleNotNeighbor(letter, idx);
    valueRef.current = idx;
  };

  const handleNotNeighbor = (letter) => {
    setSelectedLetters("");
    setSelectedLetters((prev) => prev.concat(letter));
    return;
  };

  const handleSubmit = () => {
    console.log(selectedLetters);
    const hasMatch = words.some(
      (word) => selectedLetters.toLowerCase() === word.toLowerCase()
    );
    if (hasMatch) {
      setSelectedLetters("");
      setCorrectGuesses((prev) => [...prev, selectedLetters]);
      const newWords = words.filter((word) => selectedLetters !== word);
      setWords(newWords);
    }
    checkFoundAll();
  };

  const handleClearSelection = () => {
    setSelectedLetters("");
    setHorizontal(true);
    setVertical(true);
  };

  const isNeighbor = (idx) => {
    const prevIdx = valueRef.current;

    if (idx - 1 === prevIdx || idx + 1 === prevIdx) {
      setHorizontal(true);
      setVertical(false);
    } else if (idx - 15 === prevIdx || idx + 15 === prevIdx) {
      setVertical(true);
      setHorizontal(false);
    }

    if (horizontal) {
      const isVertical = checkVertical(idx);
      return isVertical;
    } else if (vertical) {
      const isHorizontal = checkHorizontal(idx);
      return isHorizontal;
    }
    return false;
  };

  const restartGame = () => {
    valueRef.current = null;
    setWords(
      randomWords({
        exactly: 5,
        formatter: (word) => {
          return word.length >= 7
            ? fourLetterWords[
                Math.floor(Math.random() * fourLetterWords.length)
              ]
            : word;
        },
      })
    );

    setSelectedLetters("");
    setCorrectGuesses([]);
    setHorizontal(true);
    setVertical(true);
  };

  return (
    <>
      <div className="flex justify-center align-center">
        <div className="grid-container">
          {jumbledArray.map((letter, idx) => {
            return (
              <div
                className="board-letters"
                key={idx}
                onClick={() => handleLetterClick(letter, idx)}
              >
                {letter}
              </div>
            );
          })}
        </div>

        <div className="words-to-find-container">
          <h3>Words to find:</h3>
          {words.map((word, idx) => (
            <div className="words-to-find" key={idx}>
              {word}
            </div>
          ))}
        </div>
      </div>
      <div className="clicked-words-container">
        <h1 className="clicked-words">{selectedLetters}</h1>
      </div>
      <div className="btn-container">
        <button
          className="btn btn-clear-selection"
          onClick={handleClearSelection}
        >
          Clear Selection
        </button>
        <button className="btn btn-submit" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
}
