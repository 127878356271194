let randomNum;
let colNum;
let maxRowNum;
let reverse;

changeRandNumAndColNum();

export function placeLetterVertical(wordsArray2D, jumbledArray, visited) {
  wordsArray2D.forEach((wordsArray) => {
    const chance = Math.random();
    chance < 0.5 ? (reverse = true) : (reverse = false);
    if (reverse) wordsArray.reverse();
    isWordFitting(wordsArray, visited, jumbledArray);

    while (checkLetterAtPos(randomNum, jumbledArray, wordsArray)) {
      changeRandNumAndColNum();
    }

    let idx = randomNum;

    wordsArray.forEach((letter) => {
      jumbledArray[idx] = letter;
      visited[idx] = idx;
      if (!(idx + 15 >= jumbledArray.length)) {
        idx += 15;
      }
    });

    changeRandNumAndColNum();
  });
}

function changeRandNumAndColNum() {
  randomNum = Math.floor(Math.random() * 106);
  colNum = Math.floor(randomNum / 15);
  maxRowNum = (colNum + 1) * 15;
}

function isWordFitting(wordsArray, visited, jumbledArray) {
  const n = wordsArray.length;
  let spaces = availableSpace(jumbledArray);
  while (visited[randomNum] || n > spaces) {
    changeRandNumAndColNum();
    spaces = availableSpace(jumbledArray);
  }
}

function checkLetterAtPos(idx, jumbledArray, wordsArray) {
  const n = wordsArray.length;
  for (let i = 0; i < n; i++) {
    if (jumbledArray[idx] !== "0") {
      return true;
    }
    if (idx + 15 > jumbledArray.length) break;
    idx += 15;
  }
  return false;
}

function availableSpace(jumbledArray) {
  let count = 0;
  let idx = randomNum;

  for (let i = 0; i < 10 - colNum; i++) {
    if (jumbledArray[idx] === "0") {
      count++;
      if (idx + 15 > jumbledArray.length) break;

      idx += 15;
    } else {
      break;
    }
  }
  return count;
}
